import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import logo from './logo.svg';
// import './App.css';
import HomePage from './pages/HomePage';
import MapsPage from './pages/MapsPage';

function App() {
  //------------------------------------------------------------------//
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/areas' element={<MapsPage />} />
        <Route path='/areas/:areaName' element={<MapsPage />} />
        <Route path='/homes/:homeId' element={<MapsPage />} />
      </Routes>
    </Router>
  );
  //------------------------------------------------------------------//
}

export default App;
