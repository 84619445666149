import React, { useEffect, useRef, useState, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import { createCustomEqual } from "fast-equals";
// import { isLatLngLiteral } from "@googlemaps/typescript-guards";

import Footer from '../components/Footer';
import Topnav from '../components/Topnav';
import './MapsPage.css';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

//type Props = { area?: string };

//function MapsPage() {
const MapsPage: React.FC = () => {
//const MapsPage = ({ area } : Props) => {
    // window.lifecycleQueue.addScriptToEvent('https://s.zillowstatic.com/s3/pfs/topnav-ad9cb28601a36219f787.js', "priorityUIReady");
  //------------------------------------------------------------------//
  const allAreas = [
    {name: 'Uttara', lat: 23.861316, lng: 90.392549},
    {name: 'Banani', lat: 23.781316, lng: 90.402549},
    {name: 'Gulshan', lat: 23.778316, lng: 90.412549},
    {name: 'Baridhara', lat: 23.796316, lng: 90.415549},
    {name: 'Dhanmondi', lat: 23.736616, lng: 90.373449},
  ];
  const allHomes = [
    {id: 'Uttara_3_4_47', address: 'House: 47, Road: 4, Sector 3, Uttara, Dhaka 1230',
     project: 'A5, Aranno Nibash, Nagar Homes', price: '20,000,000', bed: 3, bath: 3, sft: '2,350',
     catg: 'Apartment for sale', highlight: '16 days on Lalkella', image: 'Uttara_3_4_47.jpg',
     lat: 23.866148, lng: 90.397413, area: 'Uttara', city: 'Dhaka', listingType: 'Agent'
     //latLng: {lat: 23.866148, lng: 90.397513} as google.maps.LatLngLiteral,
    },
    {id: 'Uttara_4_3_43_Nagar_Tilottama', address: 'House: 42, Road: 3, Sector 4, Uttara, Dhaka 1230',
     project: 'A2, Tilottama, Nagar Homes', price: '18,000,000', bed: 3, bath: 2, sft: '1,975',
     catg: 'Apartment for sale', highlight: '32 days on Lalkella', image: 'Uttara_4_3_43_Nagar_Tilottama.jpg',
     lat: 23.861509, lng: 90.404489, area: 'Uttara', city: 'Dhaka', listType: 'Agent'},
    {id: 'Uttara_4_7_50', address: 'House: 50, Road: 7, Sector 4, Uttara, Dhaka 1230',
     project: 'A1, Silverstar, Asset Developments', price: '28,500,000', bed: 3, bath: 3, sft: '2,400',
     catg: 'Apartment for sale', highlight: '55 days on Lalkella', image: 'ban3.jpg',
     lat: 23.862259, lng: 90.401309, area: 'Uttara', city: 'Dhaka', listType: 'Agent'},
    {id: 'Uttara_6_5_3_Navana_Cresselia', address: 'Plot: 3, Road: 5, Sector 6, Uttara, Dhaka 1230',
     project: 'A4-8, Cresselia, Navana Real Estate', price: '21,500,000', bed: 3, bath: 3, sft: '2,180',
     catg: 'Apartment for sale', highlight: '46 days on Lalkella', image: 'Uttara_6_5_3_Navana_Cresselia.jpg',
     lat: 23.873534, lng: 90.404559, area: 'Uttara', city: 'Dhaka', listType: 'Agent'},
    {id: 'Uttara_7_12_5_Navana_Sunnyside', address: 'Plot: 5, Road: 12, Sector 7, Uttara, Dhaka 1230',
     project: 'A4-8, Sunny Side, Navana Real Estate', price: '17,500,000', bed: 2, bath: 2, sft: '1,570',
     catg: 'Apartment for sale', highlight: '41 days on Lalkella', image: 'Uttara_7_12_5_Navana_Sunnyside.jpg',
     lat: 23.868626, lng: 90.396467, area: 'Uttara', city: 'Dhaka', listType: 'Agent'},
    {id: 'Uttara_11_01_Apex_Property', address: 'Road: 01, Sector 11, Uttara, Dhaka 1230',
     project: 'Duplex Apartment, Apex Property', price: '44,000,000', bed: 4, bath: 3, sft: '3,150',
     catg: 'Duplex Apt for sale', highlight: 'Luxurious duplex apartment', image: 'Uttara_11_01_Apex_Property.jpg',
     lat: 23.874783, lng: 90.395426, area: 'Uttara', city: 'Dhaka', listType: 'Agent'},
    {id: 'Banani_E_13_113_Anwar_Monsoon', address: 'Plot: 113, Road: 13, Block E, Banani, Dhaka 1213',
     project: 'Monsoon Blue, Anwar Landmark', price: '24,000,000', bed: 3, bath: 3, sft: '2110-2120',
     catg: 'Apartments for sale', highlight: '55 days on Lalkella', image: 'Banani_E_13_113_Anwar_Monsoon.jpg',
     lat: 23.7906876, lng: 90.4085004, area: 'Banani', city: 'Dhaka', listType: 'Agent'},
    {id: 'Banani_J_18_38_Duplex_House', address: 'House: 38, Road: 18, Block J, Banani, Dhaka 1213',
     project: 'Duplex House, Private Property', price: '85,000,000', bed: 4, bath: 4, sft: '4,500',
     catg: 'Property for sale', highlight: 'Spacious duplex house', image: 'Banani_J_18_38_Duplex_House.jpg',
     lat: 23.7961679, lng: 90.4055601, area: 'Banani', city: 'Dhaka', listType: 'Private'},
    {id: 'Banani_A_25_46_Shanta_Akash', address: 'Plot: 46, Road: 25, Block A, Banani, Dhaka 1213',
     project: 'Monsoon Blue, Anwar Landmark', price: '24,500,000', bed: 3, bath: 3, sft: '2110-2120',
     catg: 'Apartments for sale', highlight: '42 days on Lalkella', image: 'Banani_A_25_46_Shanta_Akash.jpg',
     lat: 23.7982024, lng: 90.4041750, area: 'Banani', city: 'Dhaka', listType: 'Agent'},
    {id: 'Banani_A_25_104_Asset_BlueRidge', address: 'House: 104, Road: 25, Block A, Banani, Dhaka 1213',
     project: 'Blue Ridge, Asset Developments', price: '23,500,000', bed: 3, bath: 3, sft: '2110-2120',
     catg: 'Apartments for sale', highlight: '63 days on Lalkella', image: 'Banani_A_25_104_Asset_BlueRidge.jpg',
     lat: 23.7976088, lng: 90.4029701, area: 'Banani', city: 'Dhaka', listType: 'Agent'},
    {id: 'Gulshan_1_23_8_Duplex_House', address: 'House: 8, Road: 23, Gulshan 1, Dhaka 1212',
     project: 'Duplex House, Private Property', price: '92,500,000', bed: 4, bath: 4, sft: '4,520',
     catg: 'Property for sale', highlight: 'Spacious duplex house', image: 'Gulshan_1_23_8_Duplex_House.jpg',
     lat: 23.782075, lng: 90.415447, area: 'Gulshan', city: 'Dhaka', listType: 'Private'},
    {id: 'Gulshan_1_29_2E_Private_Apt', address: 'House: 2E, Road: 29, Gulshan 1, Dhaka 1212',
     project: 'A2, Apartment, Private Property', price: '24,500,000', bed: 3, bath: 3, sft: '2190',
     catg: 'Apartments for sale', highlight: '52 days on Lalkella', image: 'Gulshan_1_29_2E_Private_Apt.jpg',
     lat: 23.784679, lng: 90.415019, area: 'Gulshan', city: 'Dhaka', listType: 'Private'},
    {id: 'Gulshan_2_99_11_Team_Rabbee', address: 'House:11 , Road: 99, Gulshan 2, Dhaka 1212',
     project: 'A3, Rabbee House, Team Developers', price: '24,800,000', bed: 3, bath: 3, sft: '2110-2120',
     catg: 'Apartments for sale', highlight: '42 days on Lalkella', image: 'Gulshan_2_99_11_Team_Rabbee.jpg',
     lat: 23.794139, lng: 90.419214, area: 'Gulshan', city: 'Dhaka', listType: 'Agent'},
    {id: 'Dhanmondi_7_36_Placid_Lake', address: 'House: 36, Road: 7, Dhanmondi, Dhaka 1205',
     project: '303, Placid Lake Apartment', price: '20,500,000', bed: 3, bath: 3, sft: '1875',
     catg: 'Apartment for sale', highlight: '22 days on Lalkella', image: 'Dhanmondi_7_36_Placid_Lake.jpg',
     lat: 23.744532, lng: 90.379135, area: 'Dhanmondi', city: 'Dhaka', listType: 'Agent'},
    {id: 'Dhanmondi_12A_176_Ascot_Villa', address: 'House: 176, Road 12A, Dhanmondi, Dhaka 1205',
     project: 'Ascot Villa, Private Property', price: '83,000,000', bed: 4, bath: 4, sft: '4,000',
     catg: 'Property for sale', highlight: 'Three storied building', image: 'Dhanmondi_12A_176_Ascot_Villa.jpg',
     lat: 23.746802, lng: 90.369120, area: 'Dhanmondi', city: 'Dhaka', listType: 'Private'},
    {id: 'Dhanmondi_4_32_Nandan_Deluxe', address: 'House: 32, Road: 4, Dhanmondi, Dhaka 1205',
     project: 'A4, Nandan Deluxe', price: '24,500,000', bed: 3, bath: 3, sft: '1725',
     catg: 'Apartment for sale', highlight: '27 days on Lalkella', image: 'Dhanmondi_4_32_Nandan_Deluxe.jpg',
     lat: 23.740896, lng: 90.378677, area: 'Dhanmondi', city: 'Dhaka', listType: 'Agent'},
  ];
  let { areaName, homeId } = useParams(), areas, homes, city = 'Dhaka';
  console.log('areaName:', areaName, ', homeId:', homeId);
  /*
  function initMap() {
    const center = {lat: 23.868481, lng: 90.400276}; // Uttara, Azampur
    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 8, center: center, scrollwheel: false
    });
  }
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDrOuDycmwoNIdYMntA_Sx0n13d91HUrzc&callback=initMap";
    script.async = true; document.body.appendChild(script);
    return () => { document.body.removeChild(script); }
  }, []);
  */
  //------------------------------------------------------------------//
  const mapCenter = { lat: 23.861316, lng: 90.392549 }; // Uttara // lat: 0, lng: 0
  let numAgent = 0, numOther = 0;
  if (homeId) {
    homes = allHomes.filter(home => home.id === homeId); console.log('homes[0]:', homes[0]);
    areaName = homes[0].area; if (homes[0].city) city = homes[0].city;
    mapCenter.lat = homes[0].lat - 0.003; mapCenter.lng = homes[0].lng;  //0.004
  } else {
    if(!areaName) areaName = 'Uttara'; console.log('areaName:', areaName, ', homeId:', homeId);
    areas = allAreas.filter(area => area.name.substring(0,areaName!.length).toLowerCase() === areaName!.substring(0,areaName!.length).toLowerCase());
    homes = allHomes.filter(home => home.area.substring(0,areaName!.length).toLowerCase() === areaName!.substring(0,areaName!.length).toLowerCase());
    numAgent = homes.filter(home => home.listType === 'Agent').length;
    numOther = homes.length - numAgent;
    if (homes.length > 0) { console.log('homes.length:', homes.length);
      const lats = homes.map(home => home.lat); console.log('lats:', lats);
      const lngs = homes.map(home => home.lng); console.log('lngs:', lngs);
      const avgLat = lats.reduce((a, b) => a + b) / lats.length;
      const avgLng = lngs.reduce((a, b) => a + b) / lngs.length;  // 0.007
      mapCenter.lat = avgLat - 0.022 + 0.01/homes.length; // 0.022*5
      mapCenter.lng = avgLng;
    } else if (areas.length >0) {
      mapCenter.lat = areas[0].lat; mapCenter.lng = areas[0].lng;
    }
    if (areas.length >0) areaName = areas[0].name;
  } console.log('mapCenter:', mapCenter);
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState((homeId?17.2:15)); //17:15  //3 initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(mapCenter);
  //------------------------------------------------------------------//
  //homes.forEach(home => setClicks([...clicks, {lat:home.lat, lng:home.lng} as google.maps.LatLngLiteral])); //{}
  //------------------------------------------------------------------//
  const onClick = (e: google.maps.MapMouseEvent) => {
    setClicks([...clicks, e.latLng!]); // avoid directly mutating state
  };
  const onIdle = (m: google.maps.Map) => { console.log("onIdle");
    setZoom(m.getZoom()!); console.log('onIdle - m.getCenter():',m.getCenter());
    if(m.getCenter()) setCenter(m.getCenter()!.toJSON());
    else { console.log('Setting center to:', center);
      setCenter(center);
    }
  };

  const form = (
    <div style={{ padding: "1rem", flexBasis: "250px", height: "100%", overflow: "auto" }}>
      <label htmlFor="zoom">Zoom</label>
      <input type="number" id="zoom" name="zoom" value={zoom}
        onChange={(event) => setZoom(Number(event.target.value))}
      /><br />
      <label htmlFor="lat">Latitude</label>
      <input type="number" id="lat" name="lat" value={center.lat}
        onChange={(event) => setCenter({ ...center, lat: Number(event.target.value) })}
      /><br />
      <label htmlFor="lng">Longitude</label>
      <input type="number" id="lng" name="lng" value={center.lng}
        onChange={(event) => setCenter({ ...center, lng: Number(event.target.value) })}
      />
      <h3>{clicks.length === 0 ? "Click on map to add markers" : "Clicks"}</h3>
      {clicks.map((latLng, i) => (
        <pre key={i}>{JSON.stringify(latLng.toJSON(), null, 2)}</pre>
      ))}
      <button onClick={() => setClicks([])}>Clear</button>
    </div>
  );

  //------------------------------------------------------------------//
  return (
    <div>
      {/* <h1>Maps Page!</h1> */}
      {/* //--------------------------------------------------// */}
      {/* <p style={{overflowY: 'scroll'}}>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
        Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>Scroll Testing<br/>
      </p> */}
      <a id="top"></a>
      <div id="wrapper" className="main-wrapper">
        <div className="zsg-modal-mask"></div>
        <Topnav />
        <div data-zrr-key="static-search-page:search-app" data-zrr-shared-data-key="mobileSearchPageStore">
          <div id="search-page-react-content">
            <section className="srp__sc-1cz9a3o-0 kcSoFJ search-page-header wide has-floating-action-bar" aria-label="filters">
              <div className="srp__sc-192rqw0-0 ZdNka search-page-header-container">
                <div id="srp-search-box">
                  <form className="Form-c11n-8-73-8__sc-iqxs9k-0 ixMAbq">
                    <div className="StyledCombobox-c11n-8-73-8__sc-rvbaft-0 gTGARZ SearchBox__StyledCombobox-rxevgz-2 linJXs">
                      <div aria-expanded="false" aria-haspopup="listbox" className="StyledAdornedInput-c11n-8-73-8__sc-1kgphdl-0 jiFdJj DropdownPopper-c11n-8-73-8__sc-1vnow1h-0 QRfkF">
                        <input type="text" id="__c11n_c3x16vvm" placeholder="Address, neighborhood, or ZIP" aria-autocomplete="list" autoComplete="new-password" role="combobox" value={(homeId?homeId:areaName+', '+city)} aria-expanded="false" className="StyledFormControl-c11n-8-73-8__sc-18qgis1-0 VwhxE Input-c11n-8-73-8__sc-4ry0fw-0 eZyfqh" />
                        <label aria-hidden="true" htmlFor="__c11n_c3x16vvm" id="__c11n_c3x16vvo" className="StyledAdornment-c11n-8-73-8__sc-1kerx9v-0 AdornmentRight-c11n-8-73-8__sc-1kerx9v-2 cpGgid SearchBox__StyledSearchBoxAdornment-rxevgz-0 eSNywU">
                          <button type="submit" className="StyledIconButton-c11n-8-73-8__sc-1pb8vz8-0 hCcISE SearchBox__StyledSearchBoxButton-rxevgz-1 cZOzLb">
                            <span className="VisuallyHidden-c11n-8-73-8__sc-t8tewe-0 eSAfkx">Submit Search</span>
                            <span>
                              <svg viewBox="0 0 32 32" aria-hidden="false" aria-describedby="search-icon-title search-icon-desc" focusable="false" role="img" className="Icon-c11n-8-73-8__sc-13llmml-0 jBfFtP">
                                <title id="search-icon-title">Search</title>
                                <desc id="search-icon-desc">A magnifying glass</desc>
                                <path stroke="none" d="M29.41,26.59,23.77,21A12,12,0,0,0,14,2c-.17,0-.33,0-.5,0s-.33,0-.5,0A11,11,0,0,0,2,13c0,.17,0,.33,0,.5s0,.33,0,.5a12,12,0,0,0,19,9.77l5.64,5.64a2,2,0,0,0,2.82-2.82ZM14,22a8,8,0,1,1,8-8A8,8,0,0,1,14,22Z"></path>
                              </svg>
                            </span>
                          </button>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="exposed-filters">
                <div className="filter-buttons">
                  <div tabIndex={-1} className="listing-type filter-button-container">
                    <button id="listing-type" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__fdiad6-0 bwOJjO filter-button_active" tabIndex={0} aria-expanded="false">
                      <span><span className="srp__sc-1s37pn8-0 ctIEeN"></span> For Sale</span>
                    </button>
                    <div className="zsg-popover_arrow-up zsg-popover-adjustable popover-thick-top popover-no-close srp__wyaiv6-0 idMqlt filter-button-popover listing-type-popover">
                      <span className="zsg-popover-arrow" style={{left: 54+'px'}}></span>
                      <div className="zsg-popover-adjustable-content">
                        <div className="srp__sc-1scjcmt-0 eFpjEy">
                          <button data-test="close-filters-button" aria-describedby="listing-type-form" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__sc-1scjcmt-2 fkEYux">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div tabIndex={-1} className="price filter-button-container">
                    <button id="price" data-test="price-filters-button" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__fdiad6-0 bwOJjO" tabIndex={0} aria-expanded="false">Price</button>
                    <div className="zsg-popover_arrow-up zsg-popover-adjustable popover-thick-top popover-no-close srp__wyaiv6-0 idMqlt filter-button-popover price-popover" data-test="price-filter-popover">
                      <span className="zsg-popover-arrow" style={{left: 54+'px'}}></span>
                      <div className="zsg-popover-adjustable-content">
                        <div className="srp__sc-1scjcmt-0 eFpjEy">
                          <button data-test="close-filters-button" aria-describedby="price-form" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__sc-1scjcmt-2 fkEYux">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div tabIndex={-1} className="beds filter-button-container">
                    <button id="beds" data-test="beds-filters-button" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__fdiad6-0 bwOJjO" tabIndex={0} aria-expanded="false">Beds &amp; Baths</button>
                    <div className="zsg-popover_arrow-up zsg-popover-adjustable popover-thick-top popover-no-close srp__wyaiv6-0 idMqlt filter-button-popover beds-popover" data-test="beds-filter-popover">
                      <span className="zsg-popover-arrow" style={{left: 54+'px'}}></span>
                      <div className="zsg-popover-adjustable-content">
                        <div className="srp__sc-1scjcmt-0 eFpjEy">
                          <button data-test="close-filters-button" aria-describedby="beds-form" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__sc-1scjcmt-2 fkEYux">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div tabIndex={-1} className="home-type filter-button-container">
                    <button id="home-type" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__fdiad6-0 bwOJjO" tabIndex={0} aria-expanded="false">Home type</button>
                    <div className="zsg-popover_arrow-up zsg-popover-adjustable popover-thick-top popover-no-close enlarged-width srp__wyaiv6-0 idMqlt filter-button-popover home-type-popover">
                      <span className="zsg-popover-arrow" style={{left: 241+'px'}}></span>
                      <div className="zsg-popover-adjustable-content">
                        <div className="srp__sc-1scjcmt-0 eFpjEy">
                          <button data-test="close-filters-button" aria-describedby="home-type-form" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__sc-1scjcmt-2 fkEYux">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div tabIndex={-1} className="more filter-button-container">
                    <button id="more" data-test="more-filters-button" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__fdiad6-0 bwOJjO" tabIndex={0} aria-expanded="false">More</button>
                    <div className="zsg-popover_arrow-up zsg-popover-adjustable popover-thick-top popover-no-close srp__wyaiv6-0 idMqlt filter-button-popover more-popover" data-test="more-filter-popover">
                      <span className="zsg-popover-arrow" style={{left: 500+'px'}}></span>
                      <div className="zsg-popover-adjustable-content">
                        <div className="srp__sc-1scjcmt-0 eFpjEy">
                          <button className="StyledTextButton-c11n-8-73-8__sc-n1gfmh-0 eZJsnu srp__sc-1scjcmt-1 bFjznq filter-popover-reset" aria-describedby="more-form">Reset all filters</button>
                          <button data-test="close-filters-button" aria-describedby="more-form" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hmRUbg srp__sc-1scjcmt-2 fkEYux">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-page-action-bar">
                <div className="action-bar-left-content">
                  <button className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hZTEzL srp__fdiad6-0 bwOJjO save-search-button" tabIndex={0} role="button" type="button" aria-label="Save search" aria-expanded="false" aria-haspopup="dialog">Save search</button>
                </div>
                <div className="action-bar-right-content"></div>
              </div>
            </section>
            <div className="search-page-container map-on-left">
              <div id="search-page-list-container" className="search-page-list-container double-column-only short-list-cards">
                <div id="grid-search-results" role="main" className="result-list-container">
                  <div className="search-page-list-header">
                    <h1 className="search-title">{(homeId?homeId:areaName+', '+city)} - {(homeId?'Home':'Real Estate & Homes')} For Sale</h1>
                    {(homeId?<br/>:(
                      <div className="srp__sc-1h8ajh8-1 dxXOox search-subtitle wrapped">
                        <div className="srp__sc-1xvvhzz-0 kfokvl search-page-category-selector limit-width">
                          <div className="category-options">
                            <button className="option left-option active-option">
                              <div className="total-text">{numAgent} </div>Agent listing{numAgent>0?'s':''}
                            </button>
                            <button className="option right-option">
                              <div className="total-text">{numOther} </div>Other listing{numOther>0?'s':''}
                            </button>
                          </div>
                        </div>
                        <div className="srp__sc-5mqxuh-0 jifEpS sort-options visible wrapped">
                          <strong id="sort_label">Sort by:</strong>
                          <button data-test="sort-popover-dropdown-button" aria-label="Sort options, Homes for You selected" aria-expanded="false" id="sort-popover" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 kfdyNY DropdownButton-c11n-8-73-8__sc-i5s403-0 srp__sc-5mqxuh-1 huXSnI DropdownPopper-c11n-8-73-8__sc-1vnow1h-0 QRfkF MenuPopper-c11n-8-73-8__sc-1uu201g-0 hfnpRg a11y-pagination-focus" aria-haspopup="menu">Homes for You
                            <span className="StyledButtonIcon-c11n-8-73-8__sc-wpcbcc-1 dQIlXz">
                              <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-73-8__sc-13llmml-0 jBfFtP">
                                <title>Chevron Down</title>
                                <path stroke="none" d="M29.7 8.8a1 1 0 00-1.4 0L16 21 3.7 8.8a1 1 0 00-1.4 0 1 1 0 000 1.4l13 13a1 1 0 001.4 0l13-13a1 1 0 000-1.4z"></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div></div>
                  <ul className="List-c11n-8-73-8__sc-1smrmqp-0 srp__sc-1psn8tk-0 bfcHMx photo-cards photo-cards_extra-attribution with_constellation">
                    {/* <!-- ----------------------------------------------------------------- ----> */}
                    {homes.map(home => <li className="ListItem-c11n-8-73-8__sc-10e22w8-0 srp__hpnp3q-0 enEXBq with_constellation" key={home.id}>
                      <article role="presentation" data-test="property-card" id={home.id} className="StyledPropertyCard-c11n-8-73-8__sc-jvwq6q-0 gHGLmX srp__sc-15y3msw-0 epgJFL property-card list-card_not-saved">
                        <div className="StyledCard-c11n-8-73-8__sc-rmiu6p-0 kgwBYP StyledPropertyCardBody-c11n-8-73-8__sc-1p5uux3-0 lhOvrt">
                          <div className="StyledPropertyCardDataWrapper-c11n-8-73-8__sc-1omp4c3-0 gXNuqr property-card-data">
                            <a href={"/homes/"+home.id} data-test="property-card-link" className="StyledPropertyCardDataArea-c11n-8-73-8__sc-yipmu-0 lhIXlm property-card-link" tabIndex={0}>
                              <address data-test="property-card-addr">{home.address}</address>
                            </a>
                            <div className="StyledPropertyCardDataArea-c11n-8-73-8__sc-yipmu-0 hTcpwx">
                              {home.project}
                            </div>
                            <div className="StyledPropertyCardDataArea-c11n-8-73-8__sc-yipmu-0 hRqIYX">
                              <span data-test="property-card-price">Tk{home.price}</span>
                            </div>
                            <div className="StyledPropertyCardDataArea-c11n-8-73-8__sc-yipmu-0 ghGYOB">
                              <span className="StyledPropertyCardHomeDetails-c11n-8-73-8__sc-1mlc4v9-0 jlVIIO">
                                <span><b>{home.bed}</b> bds</span>
                                <span><b>{home.bath}</b> ba</span>
                                <span><b>{home.sft}</b> sqft</span>
                              </span> - {home.catg}
                            </div>
                          </div>
                          <div className="StyledPropertyCardPhotoWrapper-c11n-8-73-8__sc-204bo4-0 hfHVtg">
                            <div className="StyledPropertyCardPhotoHeader-c11n-8-73-8__sc-10m3z6y-0 gimcQU">
                              <div className="StyledPropertyCardBadgeArea-c11n-8-73-8__sc-wncxdw-0 hlsElW">
                                <span className="StyledPropertyCardBadge-c11n-8-73-8__sc-6gojrl-0 dIjKVA">{home.highlight}</span>
                              </div>
                              <div className="StyledPropertyCardSaveArea-c11n-8-73-8__sc-15nlng8-0 dWOhlf">
                                <button aria-pressed="false" aria-label="Save" className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 ljbWUM StyledPropertyCardSaveButton-c11n-8-73-8__sc-dquvr7-0 hiRxqK property-card-save" data-test="property-card-save">
                                  <span className="StyledButtonIcon-c11n-8-73-8__sc-wpcbcc-1 dHuwmw">
                                    <svg viewBox="0 0 24 22">
                                      <path className="HeartIcon__fill" d="M17.3996 6.17511e-05C15.5119 0.00908657 13.7078 0.779206 12.3955 2.13608L11.9995 2.54408L11.6035 2.13608C10.2912 0.779206 8.48708 0.00908657 6.59946 6.17511e-05C5.15317 -0.00630912 3.7479 0.480456 2.61543 1.38007C1.08163 2.60976 0.137114 4.42893 0.0137749 6.39093C-0.109564 8.35294 0.5997 10.2761 1.96743 11.6882L2.51943 12.2522L11.3995 21.3482C11.5575 21.5095 11.7738 21.6004 11.9995 21.6004C12.2253 21.6004 12.4415 21.5095 12.5995 21.3482L21.4796 12.2522L22.0316 11.6882C23.3993 10.2761 24.1086 8.35294 23.9852 6.39093C23.8619 4.42893 22.9174 2.60976 21.3836 1.38007C20.2511 0.480456 18.8458 -0.00630912 17.3996 6.17511e-05Z"></path>
                                      <path className="HeartIcon__outline" d="M12.3955 2.13608C13.7078 0.779206 15.5119 0.00908657 17.3996 6.17511e-05C18.8458 -0.00630912 20.2511 0.480456 21.3836 1.38007C22.9174 2.60976 23.8619 4.42893 23.9852 6.39093C24.1086 8.35294 23.3993 10.2761 22.0316 11.6882L21.4796 12.2522L12.5995 21.3482C12.4415 21.5095 12.2253 21.6004 11.9995 21.6004C11.7738 21.6004 11.5575 21.5095 11.3995 21.3482L2.51943 12.2522L1.96743 11.6882C0.5997 10.2761 -0.109564 8.35294 0.0137748 6.39093C0.137114 4.42893 1.08163 2.60976 2.61543 1.38007C3.7479 0.480456 5.15317 -0.00630912 6.59946 6.17511e-05C8.48708 0.00908657 10.2912 0.779206 11.6035 2.13608L11.9995 2.54408L12.3955 2.13608ZM19.8956 3.25208C19.1854 2.69122 18.3045 2.39053 17.3996 2.40008C16.1576 2.41525 14.9717 2.91978 14.0995 3.80409L13.7155 4.21209L12.4315 5.5321C12.1927 5.77011 11.8063 5.77011 11.5675 5.5321L10.2835 4.21209L9.8995 3.80409C9.0273 2.91978 7.84145 2.41525 6.59947 2.40008C5.69165 2.39734 4.81045 2.70661 4.10345 3.27608C3.09352 4.06928 2.47292 5.25804 2.39944 6.54011C2.31914 7.81608 2.78104 9.06669 3.67145 9.98414L4.22345 10.5601L11.9995 18.5162L19.8476 10.5601L20.3996 9.98414C21.2638 9.05458 21.6991 7.80545 21.5996 6.54011C21.5329 5.2495 20.9116 4.05071 19.8956 3.25208Z"></path>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="StyledPropertyCardPhotoBody-c11n-8-73-8__sc-128t811-0 laHDtB">
                              <a href={"/homes/"+home.id} data-test="property-card-link" className="property-card-link" tabIndex={-1} aria-hidden="false">
                                <div className="StyledPropertyCardPhoto-c11n-8-73-8__sc-ormo34-0 ijneAT srp__sc-1u5uvur-0 bofBEz">
                                  <img src={"/images/homes/"+home.image} alt={home.address} aria-hidden="false" className="Image-c11n-8-73-8__sc-1rtmhsc-0 emzIcX" />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>
                    </li>)}
                    {/* <!-- ----------------------------------------------------------------- ----> */}
                  </ul>
                  <div className="srp__sc-8koroz-0 iWRaAh">
                    <div className="search-list-save-search-parent">
                      <button className="StyledButton-c11n-8-73-8__sc-wpcbcc-0 hZTEzL srp__c3ge2v-0 kbwmuQ save-search-button link-styled-button" tabIndex={0} role="button" type="button" aria-label="Save search" aria-expanded="false" aria-haspopup="dialog">
                        Save this search
                      </button> to get email alerts when listings hit the market.
                    </div>
                  </div>
                  <div className="srp__sc-1q0qrv-2 gVTKTp list-results-contexts">
                    <ul>
                      <li className="srp__sc-1q0qrv-1 hbreaq context-item">
                        <img src="https://photos.zillowstatic.com/fp/a90ae71043b28c2db9c8f14958bd7e2a-zillow_web_95_35.jpg" alt="" role="presentation" className="srp__sc-1q0qrv-0 gatTKr context-image" />
                        <p className="Text-c11n-8-73-8__sc-aiai24-0 epXNZv context-description">
                          <span>IDX information is provided exclusively for personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing.
                          Information is deemed reliable but not guaranteed.
                          This information is not verified for authenticity or accuracy, is not guaranteed and may not reflect all real estate activity in the market.
                          Copyright 2022 MLSOK, Inc. All rights reserved.

                          The listing broker’s offer of compensation is made only to participants of the MLS where the listing is filed. </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="generic-display-ad-container">
                    <div id="search-page-display-ad" data-google-query-id="CO_DhOyN9foCFQbbaAodutgCPg">
                      <div id="google_ads_iframe_/7449/zillow/search_results_1/buy_general/b_right_p1_0__container__" style={{border: [0+'pt', 'none']}}>
                        <iframe id="google_ads_iframe_/7449/zillow/search_results_1/buy_general/b_right_p1_0" name="google_ads_iframe_/7449/zillow/search_results_1/buy_general/b_right_p1_0" title="3rd party ad content" width="300" height="250" scrolling="no" marginWidth="0" marginHeight="0" frameBorder="0" role="region" aria-label="Advertisement" tabIndex="0" sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation" style={{border: 0+'px', verticalAlign: 'bottom'}} data-load-complete="true" data-google-container-id="2" loading="auto"></iframe>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div id="region-info-footer" className="Spacer-c11n-8-73-8__sc-17suqs2-0 sc-ilnxw0-0 dgPTkt">
                  <ul className="sc-j5261l-0 dtXeId">
                    <li><span>For Sale</span></li>
                    <li>
                      <a href="/dhaka/" className="Anchor-c11n-8-73-8__sc-hn4bge-0 sc-i8axjz-0 bpweNd">Dhaka</a>
                    </li>
                    <li>
                      <a href="/north/" className="Anchor-c11n-8-73-8__sc-hn4bge-0 sc-i8axjz-0 bpweNd">North</a>
                    </li>
                    <li><strong>Uttara</strong></li>
                  </ul>
                </div>
                <Footer />
              </div>
              <div id="search-page-map-container" className="search-page-map-container">
                <div id="search-page-map" className="zillow-map-control map-street-view">
                  <div className="map-container" style={{position: 'absolute', width: 100+'%', height: 100+'%'}}>
                    <div style={{position: 'absolute', width: 100+'%', height: 100+'%', overflow: 'hidden', visibility: 'visible'}}>
                      <div style={{height: 100+'%', width: 100+'%', position: 'absolute', top: 0+'px', left: 0+'px', backgroundColor: 'rgb(229, 227, 223)'}}>
                        <div className="gm-style" style={{position: 'absolute', zIndex: 0, left: 0+'px', top: 0+'px', height: 100+'%', width: 100+'%', padding: 0+'px', borderWidth: 0+'px', margin: 0+'px'}}>
                          <div>
                            <button draggable="false" aria-label="Keyboard shortcuts" title="Keyboard shortcuts" type="button" style={{background: 'none transparent', display: 'block', border: 'none', margin: 0+'px', padding: 0+'px', textTransform: 'none', appearance: 'none', position: 'absolute', cursor: 'pointer', userSelect: 'none', zIndex: 1000002, outlineOffset: 3+'px', right: 0+'px', bottom: 0+'px', transform: 'translateX(100%)'}}></button>
                          </div>
                          <div id="map" tabIndex={0} aria-label="Map" aria-roledescription="map" role="region" style={{position: 'absolute', zIndex: 0, left: 0+'px', top: 0+'px', height: 100+'%', width: 100+'%', padding: 0+'px', borderWidth: 0+'px', margin: 0+'px', cursor: 'pointer', touchAction: 'none'}}>
                            {/* <!-- ------------------------------------------------- --> */}
                            <Wrapper apiKey={"AIzaSyCy2oScFiiClguGKMcMhcKrpAoxOHsKW48"} render={render}>
                              <Map center={center} zoom={zoom} onClick={onClick} onIdle={onIdle}
                                style={{ flexGrow: "1", height: "100%" }}>
                                {/* {clicks.map((latLng, i) => (<Marker key={i} position={latLng} />))} */}
                                {homes.map((home) => (
                                  <Marker key={home.id} position={{lat: home.lat, lng: home.lng}} />
                                ))}
                              </Map>
                            </Wrapper>
                            {/* <!-- ------------------------------------------------- --> */}
                            </div>
                          <div className="gm-style-moc" style={{zIndex: 4, position: 'absolute', height: 100+'%', width: 100+'%', padding: 0+'px', borderWidth: 0+'px', margin: 0+'px', left: 0+'px', top: 0+'px', opacity: 0}}>
                            <p className="gm-style-mot"></p>
                          </div>
                        </div>
                        <iframe aria-hidden="true" frameBorder="0" tabIndex={-1} style={{zIndex: -1, position: 'absolute', width: 100+'%', height: 100+'%', top: 0+'px', left: 0+'px', border: 'none'}}></iframe>

                      </div>
                    </div>
                  </div>
                  <div className="search-page-map-overlay">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* //--------------------------------------------------// */}
    </div>
  );
  //------------------------------------------------------------------//
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({center, zoom, onClick, onIdle, children, style, ...options}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  useEffect(() => {
    if (ref.current && !map) setMap(new window.google.maps.Map(ref.current, {center, zoom}));
  }, [ref, map]);
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  /*useDeepCompareEffectForMaps(() => {
    if (map) map.setOptions(options);
  }, [map, options]);*/
  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) map.addListener("click", onClick);
      if (onIdle) map.addListener("idle", () => onIdle(map));
    }
  }, [map, onClick, onIdle]);
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  useEffect(() => {
    if (!marker) setMarker(new google.maps.Marker());
    // remove marker from map on unmount
    return () => {
      if (marker) marker.setMap(null);
    };
  }, [marker]);
  useEffect(() => {
    if (marker) marker.setOptions(options);
  }, [marker, options]);
  return null;
};

/*const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    // TODO extend to other types
    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);*/

/*function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();
  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}*/

/*function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}*/

export default MapsPage;
