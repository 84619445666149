import { useState } from 'react';
// import logo from '../logo.svg';
import './HomePage.css';
import Topnav from '../components/Topnav';
import Footer from '../components/Footer';

function HomePage() {
  const [searchText, setSearchText] = useState(''); // let searchText = '';
  const searchHandler = (event: any) => {
    event.preventDefault();
    console.log('App.searchHandler - searchText:', searchText);
    setSearchText(''); // searchText = '';
    // window.location.href = "http://localhost:3000/homes";
    window.location.href = "/areas/" + searchText;
  };
  const textChangeHandler = (event: any) => {
    // console.log('event.target.value:', event.target.value);
    setSearchText(event.target.value); // searchText = event.target.value;
    // console.log('App.textChangeHandler - searchText:', searchText);
  };
  //------------------------------------------------------------------//
  // script in topnav
  // window.__USER_SESSION_INITIAL_STATE__ = { user: { loggedIn: false } };
  //------------------------------------------------------------------//
  return (
    <div>
      <Topnav />
      {/* <!-------------------------------------------> */}
      <main>
        <div className="home-page-container">
          <div id="omp-container"></div>
          <div data-mobile="false" className="sc-1psu9y7-0 dCkeQu sc-ims7rz-0 ceTvTN bkgimg">
            <div className="sc-1psu9y7-2 diDvWr"></div>
            <div className="sc-1ov1ywu-0 sc-1psu9y7-1 jZNgwT iqnNNG">
              <div className="sc-1ov1ywu-0 sc-ims7rz-1 cqzEkR jDLtcc">
                <div className="sc-7bab5z-0 grwkrw ">
                  <h1 className="Text-c11n-8-69-2__sc-aiai24-0 StyledHeading-c11n-8-69-2__sc-ktujwe-0 bffrvW sc-7bab5z-1 jvMbGp">Find it. Tour it. Own it.</h1>
                  <h2 className="Text-c11n-8-69-2__sc-aiai24-0 StyledHeading-c11n-8-69-2__sc-ktujwe-0 kNfwmi sc-7bab5z-1 jvMbGp"></h2>
                </div>
                <div id="search-bar">
                  <div className="sc-1dzx782-0 KiRwB ">
                    <div className="Flex-c11n-8-69-2__sc-n94bjd-0 sc-17uc5u3-0  jYxjRF ">
                      <form className="react-autosuggest__container num-rows-5" role="search" onSubmit={searchHandler}>
                        <div className="react-autosuggest__container">
                          <div className="StyledAdornedInput-c11n-8-69-2__sc-1kgphdl-0 dGvVKI SearchBox-c11n-8-69-2__sc-6uapbf-0 sc-1lfawsc-0 kAIzzX dvwpwM ">
                            <input type="text" role="combobox" aria-owns="react-autowhatever-1" aria-expanded="false" autoComplete="off" aria-autocomplete="list" aria-controls="react-autowhatever-1" className="StyledFormControl-c11n-8-69-2__sc-18qgis1-0 jNDtkv Input-c11n-8-69-2__sc-4ry0fw-0 foXcRe react-autosuggest__input" placeholder="Enter an address, neighborhood, city, or ZIP code" aria-label="Search: Suggestions appear below" value={searchText} onChange={textChangeHandler} id="search-box-input"/>
                            <span className="StyledAdornment-c11n-8-69-2__sc-1kerx9v-0 AdornmentRight-c11n-8-69-2__sc-1kerx9v-2 hskEYZ fAegHK " aria-hidden="false">
                              <button id="search-icon" className="sc-1bvnalc-1 fspXPt " aria-label="Submit Search">
                                <svg viewBox="0 0 32 32" className="Icon-c11n-8-69-2__sc-13llmml-0 fhGCBg sc-1bvnalc-0 kzKZEl" aria-hidden="true" focusable="false" role="img">
                                  <path stroke="none" d="M29.41,26.59,23.77,21A12,12,0,0,0,14,2c-.17,0-.33,0-.5,0s-.33,0-.5,0A11,11,0,0,0,2,13c0,.17,0,.33,0,.5s0,.33,0,.5a12,12,0,0,0,19,9.77l5.64,5.64a2,2,0,0,0,2.82-2.82ZM14,22a8,8,0,1,1,8-8A8,8,0,0,1,14,22Z"></path>
                                </svg>
                              </button>
                            </span>
                          </div>
                          <div id="react-autowhatever-1" className="react-autosuggest__suggestions-container"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>{/* <!-- search-bar --> */}
                <div id="scroll-indicator"></div>
              </div>
            </div>
          </div>{/* <!-- bkgimg --> */}
        </div>{/* <!-- home-page-container --> */}
        <div id="recs-container"></div>
        <div id="below-fold-container" className="sc-1gjebnj-0 jtvWcK"></div>
        <div className="sc-10wuuzs-0 bWwhKS">
          <div className="sc-1ov1ywu-0 sc-10wuuzs-4 cqzEkR jmTIyO">
            <div data-shownyfairhousingmsg="false" className="sc-1ov1ywu-0 sc-10wuuzs-6 cqzEkR fqeJyA">
              <h3 data-shownyfairhousingmsg="true" className="Text-c11n-8-69-2__sc-aiai24-0 StyledHeading-c11n-8-69-2__sc-ktujwe-0 cbpJNa sc-10wuuzs-5 cJThrL">You have a right to fair housing.</h3>
              <a href="https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf">Learn about New York Fair Housing protections</a>
            </div>
          </div>
          <div id="region-footer-links" className="Flex-c11n-8-69-2__sc-n94bjd-0 sc-10wuuzs-1 dmlMjO jAiHhK">
            <ul className="sc-10wuuzs-2 lnpwlZ">
              <li className="sc-10wuuzs-3 dDkGna">
                <div className="sc-1pd1jzu-0 eSssbo">
                  <button aria-expanded="false" className="StyledTextButton-c11n-8-69-2__sc-n1gfmh-0 iLilLi sc-1p66692-0 bytAxr">
                    <span className="Text-c11n-8-69-2__sc-aiai24-0 iVbnGi">Real Estate</span>
                  </button><ul className="sc-vxl3rc-0 dSwiOK">
                    <li><a href="/browse/homes/">Browse all homes</a></li>
                    <li><a href="/albuquerque-nm/">Albuquerque real estate</a></li>
                    <li><a href="/atlanta-ga/">Atlanta real estate</a></li>
                    <li><a href="/austin-tx/">Austin real estate</a></li>
                    <li><a href="/baltimore-md/">Baltimore real estate</a></li>
                    <li><a href="/boston-ma/">Boston real estate</a></li>
                    <li><a href="/calgary-ab/">Calgary real estate</a></li>
                    <li><a href="/charlotte-nc/">Charlotte real estate</a></li>
                    <li><a href="/chicago-il/">Chicago real estate</a></li>
                    <li><a href="/cleveland-oh/">Cleveland real estate</a></li>
                    <li><a href="/colorado-springs-co/">Colorado Springs real estate</a></li>
                    <li><a href="/columbus-oh/">Columbus real estate</a></li>
                    <li><a href="/dallas-tx/">Dallas real estate</a></li>
                    <li><a href="/denver-co/">Denver real estate</a></li>
                    <li><a href="/detroit-mi/">Detroit real estate</a></li>
                    <li><a href="/edmonton-ab/">Edmonton real estate</a></li>
                    <li><a href="/el-paso-tx/">El Paso real estate</a></li>
                    <li><a href="/fort-worth-tx/">Fort Worth real estate</a></li>
                    <li><a href="/fresno-ca/">Fresno real estate</a></li>
                    <li><a href="/houston-tx/">Houston real estate</a></li>
                    <li><a href="/indianapolis-in/">Indianapolis real estate</a></li>
                    <li><a href="/jacksonville-fl/">Jacksonville real estate</a></li>
                    <li><a href="/kansas-city-mo/">Kansas City real estate</a></li>
                    <li><a href="/las-vegas-nv/">Las Vegas real estate</a></li>
                    <li><a href="/long-beach-ca/">Long Beach real estate</a></li>
                    <li><a href="/los-angeles-ca/">Los Angeles real estate</a></li>
                    <li><a href="/louisville-ky/">Louisville real estate</a></li>
                    <li><a href="/memphis-tn/">Memphis real estate</a></li>
                    <li><a href="/mesa-az/">Mesa real estate</a></li>
                    <li><a href="/miami-fl/">Miami real estate</a></li>
                    <li><a href="/milwaukee-wi/">Milwaukee real estate</a></li>
                    <li><a href="/minneapolis-mn/">Minneapolis real estate</a></li>
                    <li><a href="/nashville-tn/">Nashville real estate</a></li>
                    <li><a href="/new-orleans-la/">New Orleans real estate</a></li>
                    <li><a href="/new-york-ny/">New York real estate</a></li>
                    <li><a href="/oakland-ca/">Oakland real estate</a></li>
                    <li><a href="/oklahoma-city-ok/">Oklahoma real estate</a></li>
                    <li><a href="/omaha-ne/">Omaha real estate</a></li>
                    <li><a href="/ottawa-on/">Ottawa real estate</a></li>
                    <li><a href="/philadelphia-pa/">Philadelphia real estate</a></li>
                    <li><a href="/phoenix-az/">Phoenix real estate</a></li>
                    <li><a href="/portland-or/">Portland real estate</a></li>
                    <li><a href="/raleigh-nc/">Raleigh real estate</a></li>
                    <li><a href="/sacramento-ca/">Sacramento real estate</a></li>
                    <li><a href="/san-antonio-tx/">San Antonio real estate</a></li>
                    <li><a href="/san-diego-ca/">San Diego real estate</a></li>
                    <li><a href="/san-francisco-ca/">San Francisco real estate</a></li>
                    <li><a href="/san-jose-ca/">San Jose real estate</a></li>
                    <li><a href="/seattle-wa/">Seattle real estate</a></li>
                    <li><a href="/toronto-on/">Toronto real estate</a></li>
                    <li><a href="/tucson-az/">Tucson real estate</a></li>
                    <li><a href="/tulsa-ok/">Tulsa real estate</a></li>
                    <li><a href="/vancouver-bc/">Vancouver real estate</a></li>
                    <li><a href="/virginia-beach-va/">Virginia Beach real estate</a></li>
                    <li><a href="/washington-dc/">Washington DC real estate</a></li>
                    <li><a href="/wichita-ks/">Wichita real estate</a></li>
                  </ul>
                </div>
              </li>
              <li className="sc-10wuuzs-3 dDkGna">
                <div className="sc-1pd1jzu-0 eSssbo">
                  <button aria-expanded="false" className="StyledTextButton-c11n-8-69-2__sc-n1gfmh-0 iLilLi sc-1p66692-0 bytAxr">
                    <span className="Text-c11n-8-69-2__sc-aiai24-0 iVbnGi">Rentals</span>
                  </button><ul className="sc-vxl3rc-0 dSwiOK">
                    <li><a href="/browse/b/">Rental Buildings</a></li>
                    <li><a href="/atlanta-ga/apartments/">Atlanta apartments for rent</a></li>
                    <li><a href="/austin-tx/apartments/">Austin apartments for rent</a></li>
                    <li><a href="/baltimore-md/apartments/">Baltimore apartments for rent</a></li>
                    <li><a href="/boston-ma/apartments/">Boston apartments for rent</a></li>
                    <li><a href="/bronx-new-york-city-ny/apartments/">Bronx NYC apartments for rent</a></li>
                    <li><a href="/brooklyn-new-york-city-ny/apartments/">Brooklyn NYC apartments for rent</a></li>
                    <li><a href="/charlotte-nc/apartments/">Charlotte apartments for rent</a></li>
                    <li><a href="/chicago-il/apartments/">Chicago apartments for rent</a></li>
                    <li><a href="/dallas-tx/apartments/">Dallas apartments for rent</a></li>
                    <li><a href="/denver-co/apartments/">Denver apartments for rent</a></li>
                    <li><a href="/houston-tx/apartments/">Houston apartments for rent</a></li>
                    <li><a href="/jersey-city-nj/apartments/">Jersey City apartments for rent</a></li>
                    <li><a href="/long-beach-ca/apartments/">Long Beach apartments for rent</a></li>
                    <li><a href="/manhattan-new-york-city-ny/apartments/">Manhattan NYC apartments for rent</a></li>
                    <li><a href="/miami-fl/apartments/">Miami apartments for rent</a></li>
                    <li><a href="/minneapolis-mn/apartments/">Minneapolis apartments for rent</a></li>
                    <li><a href="/new-york-city-ny/apartments/">New York City apartments for rent</a></li>
                    <li><a href="/oakland-ca/apartments/">Oakland apartments for rent</a></li>
                    <li><a href="/oklahoma-city-ok/apartments/">Oklahoma City apartments for rent</a></li>
                    <li><a href="/philadelphia-pa/apartments/">Philadelphia apartments for rent</a></li>
                    <li><a href="/queens-new-york-city-ny/apartments/">Queens NYC apartments for rent</a></li>
                    <li><a href="/sacramento-ca/apartments/">Sacramento apartments for rent</a></li>
                    <li><a href="/san-francisco-ca/apartments/">San Francisco apartments for rent</a></li>
                    <li><a href="/seattle-wa/apartments/">Seattle apartments for rent</a></li>
                    <li><a href="/washington-dc/apartments/">Washington DC apartments for rent</a></li>
                    <li><a href="/atlanta-ga/rent-houses/">Atlanta houses for rent</a></li>
                    <li><a href="/austin-tx/rent-houses/">Austin houses for rent</a></li>
                    <li><a href="/boston-ma/rent-houses/">Boston houses for rent</a></li>
                    <li><a href="/charlotte-nc/rent-houses/">Charlotte houses for rent</a></li>
                    <li><a href="/columbus-oh/rent-houses/">Columbus houses for rent</a></li>
                    <li><a href="/fort-worth-tx/rent-houses/">Fort Worth houses for rent</a></li>
                    <li><a href="/fresno-ca/rent-houses/">Fresno houses for rent</a></li>
                    <li><a href="/houston-tx/rent-houses/">Houston houses for rent</a></li>
                    <li><a href="/indianapolis-in/rent-houses/">Indianapolis houses for rent</a></li>
                    <li><a href="/jacksonville-fl/rent-houses/">Jacksonville houses for rent</a></li>
                    <li><a href="/las-vegas-nv/rent-houses/">Las Vegas houses for rent</a></li>
                    <li><a href="/memphis-tn/rent-houses/">Memphis houses for rent</a></li>
                    <li><a href="/milwaukee-wi/rent-houses/">Milwaukee houses for rent</a></li>
                    <li><a href="/nashville-tn/rent-houses/">Nashville houses for rent</a></li>
                    <li><a href="/oakland-ca/rent-houses/">Oakland houses for rent</a></li>
                    <li><a href="/oklahoma-city-ok/rent-houses/">Oklahoma City houses for rent</a></li>
                    <li><a href="/philadelphia-pa/rent-houses/">Philadelphia houses for rent</a></li>
                    <li><a href="/phoenix-az/rent-houses/">Phoenix houses for rent</a></li>
                    <li><a href="/portland-or/rent-houses/">Portland houses for rent</a></li>
                    <li><a href="/san-antonio-tx/rent-houses/">San Antonio houses for rent</a></li>
                    <li><a href="/san-francisco-ca/rent-houses/">San Francisco houses for rent</a></li>
                    <li><a href="/san-jose-ca/rent-houses/">San Jose houses for rent</a></li>
                    <li><a href="/tampa-fl/rent-houses/">Tampa houses for rent</a></li>
                    <li><a href="/tucson-az/rent-houses/">Tucson houses for rent</a></li>
                    <li><a href="/washington-dc/rent-houses/">Washington DC houses for rent</a></li>
                  </ul>
                </div>
              </li>
              <li className="sc-10wuuzs-3 dDkGna">
                <div className="sc-1pd1jzu-0 eSssbo">
                  <button aria-expanded="false" className="StyledTextButton-c11n-8-69-2__sc-n1gfmh-0 iLilLi sc-1p66692-0 bytAxr">
                    <span className="Text-c11n-8-69-2__sc-aiai24-0 iVbnGi">Mortgage Rates</span>
                  </button><ul className="sc-vxl3rc-0 dSwiOK">
                    <li><a href="/mortgage-rates/">Current mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ak/">Alaska mortgage rates</a></li>
                    <li><a href="/mortgage-rates/al/">Alabama mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ar/">Arkansas mortgage rates</a></li>
                    <li><a href="/mortgage-rates/az/">Arizona mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ca/">California mortgage rates</a></li>
                    <li><a href="/mortgage-rates/co/">Colorado mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ct/">Connecticut mortgage rates</a></li>
                    <li><a href="/mortgage-rates/de/">Delaware mortgage rates</a></li>
                    <li><a href="/mortgage-rates/fl/">Florida mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ga/">Georgia mortgage rates</a></li>
                    <li><a href="/mortgage-rates/hi/">Hawaii mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ia/">Iowa mortgage rates</a></li>
                    <li><a href="/mortgage-rates/id/">Idaho mortgage rates</a></li>
                    <li><a href="/mortgage-rates/il/">Illinois mortgage rates</a></li>
                    <li><a href="/mortgage-rates/in/">Indiana mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ks/">Kansas mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ky/">Kentucky mortgage rates</a></li>
                    <li><a href="/mortgage-rates/la/">Louisiana mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ma/">Massachusetts mortgage rates</a></li>
                    <li><a href="/mortgage-rates/md/">Maryland mortgage rates</a></li>
                    <li><a href="/mortgage-rates/me/">Maine mortgage rates</a></li>
                    <li><a href="/mortgage-rates/mi/">Michigan mortgage rates</a></li>
                    <li><a href="/mortgage-rates/mn/">Minnesota mortgage rates</a></li>
                    <li><a href="/mortgage-rates/mo/">Missouri mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ms/">Mississippi mortgage rates</a></li>
                    <li><a href="/mortgage-rates/mt/">Montana mortgage rates</a></li>
                    <li><a href="/mortgage-rates/nc/">North Carolina mortgage rates</a></li>
                    <li><a href="/mortgage-rates/nd/">North Dakota mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ne/">Nebraska mortgage rates</a></li>
                    <li><a href="/mortgage-rates/nh/">New Hampshire mortgage rates</a></li>
                    <li><a href="/mortgage-rates/nj/">New Jersey mortgage rates</a></li>
                    <li><a href="/mortgage-rates/nm/">New Mexico mortgage rates</a></li>
                    <li><a href="/mortgage-rates/nv/">Nevada mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ny/">New York mortgage rates</a></li>
                    <li><a href="/mortgage-rates/oh/">Ohio mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ok/">Oklahoma mortgage rates</a></li>
                    <li><a href="/mortgage-rates/or/">Oregon mortgage rates</a></li>
                    <li><a href="/mortgage-rates/pa/">Pennsylvania mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ri/">Rhode Island mortgage rates</a></li>
                    <li><a href="/mortgage-rates/sc/">South Carolina mortgage rates</a></li>
                    <li><a href="/mortgage-rates/sd/">South Dakota mortgage rates</a></li>
                    <li><a href="/mortgage-rates/tn/">Tennessee mortgage rates</a></li>
                    <li><a href="/mortgage-rates/tx/">Texas mortgage rates</a></li>
                    <li><a href="/mortgage-rates/ut/">Utah mortgage rates</a></li>
                    <li><a href="/mortgage-rates/va/">Virginia mortgage rates</a></li>
                    <li><a href="/mortgage-rates/vt/">Vermont mortgage rates</a></li>
                    <li><a href="/mortgage-rates/wa/">Washington mortgage rates</a></li>
                    <li><a href="/mortgage-rates/wi/">Wisconsin mortgage rates</a></li>
                    <li><a href="/mortgage-rates/wv/">West Virginia mortgage rates</a></li>
                    <li><a href="/mortgage-rates/wy/">Wyoming mortgage rates</a></li>
                  </ul>
                </div>
              </li>
              <li className="sc-10wuuzs-3 dDkGna">
                <div className="sc-1pd1jzu-0 eSssbo">
                  <button aria-expanded="false" className="StyledTextButton-c11n-8-69-2__sc-n1gfmh-0 iLilLi sc-1p66692-0 bytAxr">
                    <span className="Text-c11n-8-69-2__sc-aiai24-0 iVbnGi">Browse Homes</span>
                  </button><ul className="sc-vxl3rc-0 dSwiOK">
                    <li><a href="/browse/homes/ca/">California</a></li>
                    <li><a href="/browse/homes/tx/">Texas</a></li>
                    <li><a href="/browse/homes/ny/">New York</a></li>
                    <li><a href="/browse/homes/fl/">Florida</a></li>
                    <li><a href="/browse/homes/il/">Illinois</a></li>
                    <li><a href="/browse/homes/pa/">Pennsylvania</a></li>
                    <li><a href="/browse/homes/oh/">Ohio</a></li>
                    <li><a href="/browse/homes/mi/">Michigan</a></li>
                    <li><a href="/browse/homes/ga/">Georgia</a></li>
                    <li><a href="/browse/homes/nc/">North Carolina</a></li>
                    <li><a href="/browse/homes/nj/">New Jersey</a></li>
                    <li><a href="/browse/homes/va/">Virginia</a></li>
                    <li><a href="/browse/homes/wa/">Washington</a></li>
                    <li><a href="/browse/homes/ma/">Massachusetts</a></li>
                    <li><a href="/browse/homes/in/">Indiana</a></li>
                    <li><a href="/browse/homes/az/">Arizona</a></li>
                    <li><a href="/browse/homes/tn/">Tennessee</a></li>
                    <li><a href="/browse/homes/mo/">Missouri</a></li>
                    <li><a href="/browse/homes/md/">Maryland</a></li>
                    <li><a href="/browse/homes/wi/">Wisconsin</a></li>
                    <li><a href="/browse/homes/mn/">Minnesota</a></li>
                    <li><a href="/browse/homes/co/">Colorado</a></li>
                    <li><a href="/browse/homes/al/">Alabama</a></li>
                    <li><a href="/browse/homes/sc/">South Carolina</a></li>
                    <li><a href="/browse/homes/la/">Louisiana</a></li>
                    <li><a href="/browse/homes/ky/">Kentucky</a></li>
                    <li><a href="/browse/homes/or/">Oregon</a></li>
                    <li><a href="/browse/homes/ok/">Oklahoma</a></li>
                    <li><a href="/browse/homes/ct/">Connecticut</a></li>
                    <li><a href="/browse/homes/ia/">Iowa</a></li>
                    <li><a href="/browse/homes/ms/">Mississippi</a></li>
                    <li><a href="/browse/homes/ar/">Arkansas</a></li>
                    <li><a href="/browse/homes/ks/">Kansas</a></li>
                    <li><a href="/browse/homes/ut/">Utah</a></li>
                    <li><a href="/browse/homes/nv/">Nevada</a></li>
                    <li><a href="/browse/homes/nm/">New Mexico</a></li>
                    <li><a href="/browse/homes/wv/">West Virginia</a></li>
                    <li><a href="/browse/homes/ne/">Nebraska</a></li>
                    <li><a href="/browse/homes/id/">Idaho</a></li>
                    <li><a href="/browse/homes/hi/">Hawaii</a></li>
                    <li><a href="/browse/homes/me/">Maine</a></li>
                    <li><a href="/browse/homes/nh/">New Hampshire</a></li>
                    <li><a href="/browse/homes/ri/">Rhode Island</a></li>
                    <li><a href="/browse/homes/mt/">Montana</a></li>
                    <li><a href="/browse/homes/de/">Delaware</a></li>
                    <li><a href="/browse/homes/sd/">South Dakota</a></li>
                    <li><a href="/browse/homes/ak/">Alaska</a></li>
                    <li><a href="/browse/homes/nd/">North Dakota</a></li>
                    <li><a href="/browse/homes/vt/">Vermont</a></li>
                    <li><a href="/browse/homes/dc/district-of-columbia-county/">Washington, DC</a></li>
                    <li><a href="/browse/homes/wy/">Wyoming</a></li>
                    <li><a href="/browse/homes/pr/">Puerto Rico</a></li>
                    <li><a href="/browse/homes/vi/">Virgin Islands</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>{/* <!-- region-footer-links --> */}
        </div>
        <div id="icon-font"></div>
        <div id="csat-dialog"></div>
        <div id="lazy-zsg-styles"></div>
      </main>
      {/* <!-------------------------------------------> */}
      <div data-testid="footer">
        <style></style>
        <Footer />
      </div>{/* <!-- footer --> */}
      {/* <!-------------------------------------------> */}

      {/* <br /><br /><br /><br /><br /><br /><br /><br />
      <h1>Lalkella</h1>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div> */}
    </div>
  );
  //------------------------------------------------------------------//
}

export default HomePage;
