import React from 'react';

function Topnav() {
  //------------------------------------------------------------------//
  return (
    <div data-testid="topnav">
      {/* <!-------------------------------------------> */}
        <div id='pfs-upsell'></div>
        <div id='pfs-nav-wrapper'>
          <style></style>
          <div id="page-header-container">
            <div role="banner" className="Toast-sc-nim36i-0 edfOAq Toast-c11n-8-62-4__sc-18t8o9m-0 lbrRxN Banner-c11n-8-62-4__sc-tm5jty-0 pfs__sc-1ivgdvb-0 LHWfJ">
              <div className="Toast__Icon-sc-nim36i-1 ddlUZa">
                <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 fOwLTf">
                  <title>Warning</title>
                  <path stroke="none" d="M29.75 26l-12-22a2 2 0 00-3.52 0l-12 22A2 2 0 004 29h24a2 2 0 001.75-3zM17.5 24a1.5 1.5 0 01-3 0 1.49 1.49 0 011.5-1.5 1.48 1.48 0 011.5 1.5zm-.44-4.83a1.09 1.09 0 01-2.11 0l-.69-6.4a1.74 1.74 0 113.48 0z"></path>
                </svg>
              </div>
              <div className="Toast__Body-sc-nim36i-4 gWwFfa">
                This browser is no longer supported. Please switch to a supported browser or download one of our Mobile Apps.
              </div>
              <div className="Toast__Action-sc-nim36i-2 kysVQe">
                <a href="https://www.lalkella.com/buying/app-download/" className="StyledTextButton-c11n-8-62-4__sc-n1gfmh-0 faowej">See Mobile Apps</a>
              </div>
            </div>{/* <!-- banner --> */}
            <style></style>
            <a href="#skip-topnav-target" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1gc6ow0-0 fUFxyY"><span>Skip main navigation</span></a>
            <header data-mobilenavopen="false" data-zg-rule="topnav" className="pfs__sc-80tljk-0 pfs__sc-1chonea-0 dWZLDT znav ">
              <div className="pfs__q0n616-0 eWDfPQ znav-mask"></div>
              {/* <!-- ----------------------------------------------------------------------- --> */}
              <nav role="navigation" data-za-category="TopNav" aria-label="main" data-display-my-zillow="true" className="pfs__bqajy9-0 iezNBX">
                <div className="pfs__sc-7lof6w-0 dDvhRD">
                  <button className="pfs__sc-103s6w0-0 eiAvGo hamburger hamburger" type="button" aria-label="Open navigation"><span className="pfs__sc-103s6w0-1 kzFImv"><span className="pfs__sc-103s6w0-2 fFgKqs"></span></span>
                  </button>
                  <a href="/" aria-label="Lalkella Real Estate" accessKey="1" data-za-action="Lalkella logo click" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__j60ma-0 kxYrXn znav-topnav-logo">
                    <img src="images/icons/logo.png" alt="Lalkella logo" height="50" width="240" className="pfs__j60ma-1 eCEnCc" id="lk_logo1"/>
                    {/* <!-- img src="images/icons/logo.png" type="image/png" alt="Lalkella logo" focusable="false" height="25" width="120" class="pfs__j60ma-3 lfSOeK"/ --> */}
                  </a>
                  <ul data-zg-section="reg-login" data-display-my-zillow="true" className="pfs__sc-1elvxkv-1 pfs__sc-1elvxkv-2 pfs__sc-98mhij-0 dwLwYR">
                    <li className="pfs__sc-585qe5-0 LQquc"><a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/user/acct/login/?cid=pf" data-za-action="Sign in" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Sign in</span></a></li>
                    <li className="pfs__sc-585qe5-0 LQquc"><a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/user/acct/register/?cid=pf" data-za-action="Join" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Join</span></a></li>
                  </ul>
                </div>{/* <!-- pfs__sc-7lof6w-0 dDvhRD --> */}
                <div className="pfs__sc-1ve4drk-0 iNhaTW znav-links">
                  <div className="pfs__sc-1vxjlvm-0 gcJTZh">
                    <a href="/" title="Lalkella Real Estate" data-za-action="Lalkella logo click" data-za-category="!inherit" data-display-my-zillow="true" className="Anchor-c11n-8-62-4__sc-hn4bge-0 hjVzie">Homepage</a>
                  </div>
                  <ul data-zg-section="main" className="pfs__sc-1elvxkv-1 pfs__sc-1elvxkv-2 pfs__sc-1wickoz-0 fzMoEi">
                    <li className="pfs__sc-585qe5-0 LQquc">
                      <a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD noroute" href="/homes/" data-za-action="Buy" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Buy</span></a>
                      <button type="button" aria-label="Open Buy sub-menu" className="pfs__sc-1psx3f8-0 fjJGgI">
                        <span className="VisuallyHidden-c11n-8-62-4__sc-t8tewe-0 jUSvZE">Open Buy sub-menu</span>
                        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 kNHTkr">
                          <title>Chevron Down</title>
                          <path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path>
                        </svg>
                      </button>
                      <div data-za-label="Buy">
                        <div className="pfs__xukzw9-0 iHHBpm">
                          <div data-zg-role="drop-down-content" className="pfs__xukzw9-1 dDiZgF">
                            <ul className="pfs__xukzw9-2 cSiFji">
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Homes for sale</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/homes/for_sale/" rel="" data-za-action="Homes for sale" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Homes for sale</span></a></li>
                                      <li><a href="/homes/for_sale/fore_lt/" rel="" data-za-action="Foreclosures" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Foreclosures</span></a></li>
                                      <li><a href="/homes/fsbo/" rel="" data-za-action="For sale by owner" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>For sale by owner</span></a></li>
                                      <li><a href="/homes/for_sale/1_open/" rel="" data-za-action="Open houses" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Open houses</span></a></li>
                                    </ul>
                                  </li>
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/homes/new_homes/" rel="" data-za-action="New construction" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>New construction</span></a></li>
                                      <li><a href="/homes/coming_soon/cmsn_lt/" rel="" data-za-action="Coming soon" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Coming soon</span></a></li>
                                      <li><a href="/homes/recently_sold/" rel="" data-za-action="Recent home sales" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Recent home sales</span></a></li>
                                      <li><a href="/browse/homes/" rel="" data-za-action="All homes" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>All homes</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>{/* <!-- Homes for sale --> */}
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Bundle buying &amp; selling</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/lalkella-360/?t=z360-buy-topnav" rel="" data-za-action="Buy and sell with Lalkella 360" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Buy and sell with Lalkella 360</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>{/* <!-- Bundle buying & selling--> */}
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Resources</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/home-buying-guide/" rel="" data-za-action="Buyers Guide" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Buyers Guide</span></a></li>
                                      <li><a href="/foreclosures/" rel="" data-za-action="Foreclosure center" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Foreclosure center</span></a></li>
                                      <li><a href="/z/buying/app-download/" rel="" data-za-action="Real estate app" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Real estate app</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>{/* <!-- Resources --> */}
                            </ul>
                          </div>
                        </div>
                      </div>{/* <!-- Buy --> */}
                    </li>
                    <li className="pfs__sc-585qe5-0 LQquc">
                      <a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD noroute" href="/homes/for_rent/" data-za-action="Rent" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Rent</span></a>
                      <button type="button" aria-label="Open Rent sub-menu" className="pfs__sc-1psx3f8-0 fjJGgI">
                        <span className="VisuallyHidden-c11n-8-62-4__sc-t8tewe-0 jUSvZE">Open Rent sub-menu</span>
                        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 kNHTkr">
                          <title>Chevron Down</title>
                          <path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path>
                        </svg>
                      </button>
                      <div data-za-label="Rent">
                        <div className="pfs__xukzw9-0 iHHBpm">
                          <div data-zg-role="drop-down-content" className="pfs__xukzw9-1 dDiZgF">
                            <ul className="pfs__xukzw9-2 cSiFji">
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Search for rentals</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/homes/for_rent/apartment_duplex_type/" rel="" data-za-action="Rental buildings" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Rental buildings</span></a></li>
                                      <li><a href="/homes/for_rent/condo,apartment_duplex_type/" rel="" data-za-action="Apartments for rent" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Apartments for rent</span></a></li>
                                      <li><a href="/homes/for_rent/house_type/" rel="" data-za-action="Houses for rent" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Houses for rent</span></a></li>
                                      <li><a href="/homes/for_rent/" rel="" data-za-action="All rental listings" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>All rental listings</span></a></li>
                                      <li><a href="/browse/b/" rel="" data-za-action="All rental buildings" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>All rental buildings</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Renter Hub</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/renting/" rel="" data-za-action="Contacted rentals" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Contacted rentals</span></a></li>
                                      <li><a href="/renting/your-rental/" rel="" data-za-action="Your rental" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Your rental</span></a></li>
                                      <li><a href="/renter-hub/inbox/" rel="" data-za-action="Messages" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Messages</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Resources</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/rent-affordability-calculator/" rel="" data-za-action="Affordability calculator" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Affordability calculator</span></a></li>
                                      <li><a href="/rent/guide/" rel="" data-za-action="Renters guide" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Renters guide</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>{/* <!-- Rent --> */}
                    </li>
                    <li className="pfs__sc-585qe5-0 LQquc">
                      <a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/sell/" data-za-action="Sell" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Sell</span></a>
                      <button type="button" aria-label="Open Sell sub-menu" className="pfs__sc-1psx3f8-0 fjJGgI">
                        <span className="VisuallyHidden-c11n-8-62-4__sc-t8tewe-0 jUSvZE">Open Sell sub-menu</span>
                        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 kNHTkr">
                          <title>Chevron Down</title>
                          <path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path>
                        </svg>
                      </button>
                      <div data-za-label="Sell">
                        <div className="pfs__xukzw9-0 iHHBpm">
                          <div data-zg-role="drop-down-content" className="pfs__xukzw9-1 dDiZgF">
                            <ul className="pfs__xukzw9-2 cSiFji">
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Resources</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/sell/" rel="" data-za-action="Explore your options" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Explore your options</span></a></li>
                                      <li><a href="/how-much-is-my-home-worth/" rel="" data-za-action="See your home&#x27;s Zestimate" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>See your home&#x27;s Lestimate</span></a></li>
                                      <li><a href="/home-values/" rel="" data-za-action="Home values" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Home values</span></a></li>
                                      <li><a href="/sellers-guide/" rel="" data-za-action="Sellers guide" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Sellers guide</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Bundle buying &amp; selling</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/z/zillow-360/?t=z360-sell-topnav" rel="" data-za-action="Buy and sell with Zillow 360" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Buy and sell with Zillow 360</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Selling options</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/professionals/real-estate-agent-reviews/?service=Selling" rel="" data-za-action="Find a seller&#x27;s agent" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Find a seller&#x27;s agent</span></a></li>
                                      <li><a href="/for-sale-by-owner/" rel="" data-za-action="Post For Sale by Owner" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Post For Sale by Owner</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>{/* <!-- Sell --> */}
                    </li>
                    <li className="pfs__sc-585qe5-0 LQquc">
                      <a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/home-loans/#source=Z_Mortgagestopnav" data-za-action="Home Loans" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Home Loans</span></a>
                      <button type="button" aria-label="Open Home Loans sub-menu" className="pfs__sc-1psx3f8-0 fjJGgI">
                        <span className="VisuallyHidden-c11n-8-62-4__sc-t8tewe-0 jUSvZE">Open Home Loans sub-menu</span>
                        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 kNHTkr">
                          <title>Chevron Down</title>
                          <path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path>
                        </svg>
                      </button>
                      <div data-za-label="Home Loans">
                        <div className="pfs__xukzw9-0 iHHBpm">
                          <div data-zg-role="drop-down-content" className="pfs__xukzw9-1 dDiZgF">
                            <ul className="pfs__xukzw9-2 cSiFji">
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Shop mortgages</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/mortgages/#source=Z_Mortgageshovertopnav" rel="" data-za-action="Mortgage lenders" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Mortgage lenders</span></a></li>
                                      <li><a href="/mortgages/heloc/#source=Z_Mortgageshovertopnav" rel="" data-za-action="HELOC lenders" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>HELOC lenders</span></a></li>
                                      <li><a href="/mortgage-rates/" rel="" data-za-action="Mortgage rates" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Mortgage rates</span></a></li>
                                      <li><a href="/refinance/" rel="" data-za-action="Refinance rates" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Refinance rates</span></a></li>
                                      <li><a href="/mortgage/browse/" rel="" data-za-action="All mortgage rates" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>All mortgage rates</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Calculators</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/mortgage-calculator/" rel="" data-za-action="Mortgage calculator" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Mortgage calculator</span></a></li>
                                      <li><a href="/mortgage-calculator/refinance-calculator/" rel="" data-za-action="Refinance calculator" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Refinance calculator</span></a></li>
                                      <li><a href="/mortgage-calculator/house-affordability/" rel="" data-za-action="Affordability calculator" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Affordability calculator</span></a></li>
                                      <li><a href="/mortgage-calculator/amortization-schedule-calculator/" rel="" data-za-action="Amortization calculator" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Amortization calculator</span></a></li>
                                      <li><a href="/mortgage-calculator/debt-to-income-calculator/" rel="" data-za-action="Debt-to-Income calculator" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Debt-to-Income calculator</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Resources</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/lender-directory/" rel="" data-za-action="Lender reviews" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Lender reviews</span></a></li>
                                      <li><a href="/mortgage-learning/" rel="" data-za-action="Mortgage learning center" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Mortgage learning center</span></a></li>
                                      <li><a href="/z/buying/app-download/" rel="" data-za-action="Mortgages app" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Mortgages app</span></a></li>
                                      <li><a href="/lender-resources/" rel="" data-za-action="Lender resource center" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Lender resource center</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>{/* <!-- Home Loans --> */}
                    </li>
                    <li className="pfs__sc-585qe5-0 LQquc">
                      <a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/professionals/real-estate-agent-reviews/" data-za-action="Agent finder" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Agent finder</span></a>
                      <button type="button" aria-label="Open Agent finder sub-menu" className="pfs__sc-1psx3f8-0 fjJGgI">
                        <span className="VisuallyHidden-c11n-8-62-4__sc-t8tewe-0 jUSvZE">Open Agent finder sub-menu</span>
                        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 kNHTkr">
                          <title>Chevron Down</title>
                          <path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path>
                        </svg>
                      </button>
                      <div data-za-label="Agent finder">
                        <div className="pfs__xukzw9-0 iHHBpm">
                          <div data-zg-role="drop-down-content" className="pfs__xukzw9-1 dDiZgF">
                            <ul className="pfs__xukzw9-2 cSiFji">
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Looking for pros?</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/professionals/real-estate-agent-reviews/" rel="" data-za-action="Real estate agents" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Real estate agents</span></a></li>
                                      <li><a href="/professionals/property-manager-reviews/" rel="" data-za-action="Property managers" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Property managers</span></a></li>
                                      <li><a href="/professionals/home-inspector-reviews/" rel="" data-za-action="Home inspectors" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Home inspectors</span></a></li>
                                      <li><a href="/professionals/real-estate-services-reviews/" rel="" data-za-action="Other pros" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Other pros</span></a></li>
                                    </ul>
                                  </li>
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/professionals/home-improvement-reviews/" rel="" data-za-action="Home improvement pros" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Home improvement pros</span></a></li>
                                      <li><a href="/home-builders/" rel="" data-za-action="Home builders" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Home builders</span></a></li>
                                      <li><a href="/professionals/photographer-reviews/" rel="" data-za-action="Real estate photographers" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Real estate photographers</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">I&#x27;m a pro</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="https://premieragent.lalkella.com/products/advertising/?itc=paw_z_sitewide-agentfinder_subnav-advertising_pa-ads_a_null" rel="" data-za-action="Agent advertising" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Agent advertising</span></a></li>
                                      <li><a href="/agent-resources/" rel="" data-za-action="Agent resource center" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Agent resource center</span></a></li>
                                      <li><a href="/premier-agent/agent-account/?itc=paw_z_sitewide-agentfinder_subnav-createagentaccount_pa-reg_a_null" rel="" data-za-action="Create a free agent account" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Create a free agent account</span></a></li>
                                    </ul>
                                  </li>
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/agent-resources/agent-toolkit/real-estate-business-plan-template/" rel="" data-za-action="Real estate business plan" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Real estate business plan</span></a></li>
                                      <li><a href="/agent-resources/agent-toolkit/real-estate-follow-up-email-templates/" rel="" data-za-action="Real estate agent scripts" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Real estate agent scripts</span></a></li>
                                      <li><a href="/agent-resources/agent-toolkit/real-estate-listing-flyer-templates/" rel="" data-za-action="Listing flyer templates" data-za-label="!inherit" data-za-category="!inherit" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Listing flyer templates</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>{/* <!-- Agent finder --> */}
                    </li>
                  </ul>{/* <!-- main --> */}
                  <ul data-zg-section="marketing" data-display-my-zillow="true" data-display-agent="false" className="pfs__sc-1elvxkv-1 pfs__sc-1elvxkv-2 pfs__thqlz5-0 jrqgtg">
                    <li id="manage-rentals" className="pfs__sc-585qe5-0 LQquc znav-manage-rentals">
                      <a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD noroute" href="/rental-manager/?source=topnav&amp;itc=postbutton_sitenav" data-za-action="Manage Rentals" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Manage Rentals</span></a>
                      <button type="button" aria-label="Open Manage Rentals sub-menu" className="pfs__sc-1psx3f8-0 fjJGgI">
                        <span className="VisuallyHidden-c11n-8-62-4__sc-t8tewe-0 jUSvZE">Open Manage Rentals sub-menu</span>
                        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img" className="Icon-c11n-8-62-4__sc-13llmml-0 kNHTkr">
                          <title>Chevron Down</title>
                          <path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path>
                        </svg>
                      </button>
                      <div data-za-label="Manage Rentals">
                        <div className="pfs__xukzw9-0 iHHBpm">
                          <div data-zg-role="drop-down-content" className="pfs__xukzw9-1 dDiZgF">
                            <ul className="pfs__xukzw9-2 cSiFji">
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Rental Management Tools</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/rental-manager/post-a-listing?itc=ltr_zw_ltr_zrm-home_tpnv_post-a-listing" rel="" data-za-action="List a rental" data-za-label="!inherit" data-za-category="!inherit" id="list_a_rental" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>List a rental</span></a></li>
                                      <li><a href="/rental-manager/select-property?type=listings&amp;source=topnav&amp;itc=postbutton_topnav" rel="" data-za-action="My Listings" data-za-label="!inherit" data-za-category="!inherit" id="my_listings" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>My Listings</span></a></li>
                                      <li><a href="/rental-manager/inbox/" rel="" data-za-action="Messages" data-za-label="!inherit" data-za-category="!inherit" id="messages" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Messages</span></a></li>
                                      <li><a href="/rental-manager/select-property?type=applications&amp;source=topnav&amp;itc=postbutton_topnav" rel="" data-za-action="Applications" data-za-label="!inherit" data-za-category="!inherit" id="applications" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Applications</span></a></li>
                                      <li><a href="/rental-manager/select-property?type=leases&amp;source=topnav&amp;itc=postbutton_topnav" rel="" data-za-action="Leases" data-za-label="!inherit" data-za-category="!inherit" id="leases" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Leases</span></a></li>
                                      <li><a href="/rental-manager/select-property?type=payments&amp;source=topnav&amp;itc=postbutton_topnav" rel="" data-za-action="Payments" data-za-label="!inherit" data-za-category="!inherit" id="payments" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Payments</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <h6 className="pfs__sc-1449t2l-0 gGNmKv">Learn More</h6>
                                <ul className="pfs__c1z80u-0 jpbszD">
                                  <li>
                                    <ul className="pfs__ryrjop-0 gutigg">
                                      <li><a href="/rental-manager/?source=topnav&amp;itc=list_zrm_topnav" rel="" data-za-action="Zillow Rental Manager" data-za-label="!inherit" data-za-category="!inherit" id="zrm" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Lalkella Rental Manager</span></a></li>
                                      <li><a href="/rental-manager/price-my-rental/?itc=ltr_zw_ltr_zrm-home_tpnv_price-my-rental" rel="" data-za-action="Price My Rental" data-za-label="!inherit" data-za-category="!inherit" id="price_my_rental" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Price My Rental</span></a></li>
                                      <li><a href="/rental-manager/resources/" rel="" data-za-action="Resource Center" data-za-label="!inherit" data-za-category="!inherit" id="resource_center" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Resource Center</span></a></li>
                                      <li><a href="https://rentalmanager.lalkella.com/hc/en-us/" rel="" data-za-action="Help Center" data-za-label="!inherit" data-za-category="!inherit" id="help_center" className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__ulmj52-0 gEjusp"><span>Help Center</span></a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="pfs__sc-585qe5-0 LQquc"><a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/partners/advertise/" data-za-action="Advertise" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Advertise</span></a></li>
                  </ul>{/* <!-- marketing --> */}
                  <ul data-zg-section="help" data-display-my-zillow="true" className="pfs__sc-1elvxkv-1 pfs__sc-1elvxkv-2 pfs__sc-19pph06-0 hprrMd">
                    <li className="pfs__sc-585qe5-0 LQquc"><a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="https://helpdesk.lalkella.com/hc/en-us/" data-za-action="Help" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Help</span></a></li>
                  </ul>{/* <!-- help --> */}
                  <ul data-zg-section="reg-login" data-display-my-zillow="true" className="pfs__sc-1elvxkv-1 pfs__sc-1elvxkv-2 pfs__sc-98mhij-0 dwLwYR">
                    <li className="pfs__sc-585qe5-0 LQquc"><a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/user/acct/login/?cid=pf" data-za-action="Sign in" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Sign in</span></a></li>
                    <li className="pfs__sc-585qe5-0 LQquc"><a className="Anchor-c11n-8-62-4__sc-hn4bge-0 pfs__sc-1dpbk03-0 cwauRD" href="/user/acct/register/?cid=pf" data-za-action="Join" data-za-category="!inherit" data-active="false" data-zg-role="section-title"><span>Join</span></a></li>
                  </ul>{/* <!-- reg-login --> */}
                </div>{/* <!-- znav-links --> */}
              </nav>
              {/* <!-- ----------------------------------------------------------------------- --> */}
              </header>
            <div id="skip-topnav-target" tabIndex={-1}></div>
          </div>{/* <!-- page-header-container --> */}
        </div>{/* <!-- pfs-nav-wrapper --> */}
        <div id='sw-container'></div>
      {/* <!-------------------------------------------> */}
    </div>
  );
  //------------------------------------------------------------------//
}

export default Topnav;
